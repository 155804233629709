import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SkillsLogo from "../../static/images/skills.svg"

const Skills = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/skills/" } }
          sort: {fields: frontmatter___date, order: ASC}
          ) {
          edges {
            node {
              id
              frontmatter {
                description
                title
                path
                logo {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="container" id="skills">
        <div className="header">
          <img className="header__icon" src={SkillsLogo} alt="something" />
          <h1 className="header__title">Skills</h1>
        </div>
        <div className="wrapper">
          {data.allMarkdownRemark.edges.map(skill => (
            <div className="wrapper__item" key={skill.node.id}>
              <img
                src={skill.node.frontmatter.logo.publicURL}
                alt={`${skill.node.frontmatter.title} Logo`}
                className="wrapper__image"
              />
              <h3 className="wrapper__name">{skill.node.frontmatter.title}</h3>
            </div>
          ))}
        </div>
      </section>
    )}
  />
)

export default Skills
