import React from "react"
import { StaticQuery, graphql } from "gatsby"
import ResourcesLogo from "../../static/images/resources.svg"

const Resources = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { extension: { eq: "svg" } }) {
          edges {
            node {
              id
              publicURL
              name
            }
          }
        }
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/resources/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                path
                description
                logo {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="container" id="resources">
        <div className="header">
          <img className="header__icon" src={ResourcesLogo} alt="something" />
          <h1 className="header__title">Resources</h1>
        </div>
        <div className="wrapper wrapper--resources">
          {data.allMarkdownRemark.edges.map(resources => (
            <div className="wrapper__item" key={resources.node.id}>
              <img
                src={resources.node.frontmatter.logo.publicURL}
                alt={`${resources.node.frontmatter.title} Logo`}
                className="wrapper__image"
              />
              <a className="wrapper__name" href={resources.node.frontmatter.path} target="_blank" rel="noreferrer">
                <h3>
                  {resources.node.frontmatter.title}
                </h3>
              </a>
              <p className="wrapper__paragraph">
                {resources.node.frontmatter.description}
              </p>
            </div>
          ))}
        </div>
      </section>
    )}
  />
)

export default Resources
