import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Tool from "../../static/images/tool.svg"

const Tools = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/tools/" } }
          sort: {fields: frontmatter___date, order: ASC}
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                path
                tag
                description
                logo {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <section className="container" id="tools">
        <div className="header">
          <img className="header__icon" src={Tool} alt="something" />
          <h1 className="header__title">Tools</h1>
        </div>
        <div className="wrapper">
          {data.allMarkdownRemark.edges.map(tool => (
            <div className="wrapper__item" key={tool.node.id}>
              <img
                src={tool.node.frontmatter.logo.publicURL}
                alt={`${tool.node.frontmatter.title} Logo`}
                className="wrapper__image"
              />
              <a href={tool.node.frontmatter.path} target="_blank" rel="noreferrer">
                <h3 className="wrapper__name">{tool.node.frontmatter.title}</h3>
              </a>
            </div>
          ))}
        </div>
      </section>
    )}
  />
)

export default Tools
