import React from "react"
import { StaticQuery, graphql } from "gatsby"
import GithubLogo from "../../static/images/github.svg"

const Github = () => (
  <StaticQuery
    query={graphql`
        query {
            github {
                viewer {
                    repositories(
                    isLocked: false
                    first: 6
                    orderBy: {field: CREATED_AT, direction: DESC}
                    ownerAffiliations: OWNER
                    privacy: PUBLIC
                    ) {
                        nodes {
                            id
                            name
                            url
                            repositoryTopics(first: 100) {
                                nodes {
                                    topic {
                                        name
                                    }
                                }
                            }
                            owner {
                                url
                            }
                        }
                    }
                }
            }
        }
    `}
    render={data => (
      <div className="container" id="repositories">
            <div className="header">
                <img className="header__icon" src={GithubLogo} alt="something" />
                <h1 className="header__title">Repositories</h1>
            </div>
            <div className="wrapper wrapper--github ">
                {data.github.viewer.repositories.nodes.map(repository => (
                    <div className="wrapper__item" key={repository.id}>   
                        <a className="wrapper__name" href={repository.url} target="_blank" rel="noreferrer"><h3>{repository.name}</h3></a>
                        <p className="wrapper__owner">by Flukal</p>
                        <div className="wrapper__topics">
                            {repository.repositoryTopics.nodes.map(node => (
                                <p>{node.topic.name}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
      </div>
    )}
  />
)

export default Github
