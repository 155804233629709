import * as React from "react"
import Github from "../../static/images/github.svg"
import Linkedin from "../../static/images/linkedin.svg"


const Social = () => {
  return (
    <div className="container container--social">
        <a href="https://github.com/Flukal" alt="social icon" className="link link--button" target="_blank" rel="noreferrer">
            <img src={Github} alt="" />
            Github
        </a>
        <a href="https://www.linkedin.com/in/fulviocalderone/" alt="social icon" className="link link--button" target="_blank" rel="noreferrer">
            <img src={Linkedin} alt="" />
            Linkedin
        </a>
    </div>
  )
}

export default Social
