import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Banner from "../components/banner"
import Skills from "../components/skills"
import Tools from "../components/tools"
import Resources from "../components/resources"
import Social from "../components/social"
import Github from "../components/github"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Banner />
    <Social />
    <Skills />
    <Tools />
    <Github />
    <Resources />
  </Layout>
)

export default IndexPage
