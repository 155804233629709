import * as React from "react"

const Banner = () => {
  return (
    <>
      <div className="banner">
        <h1 className="banner__title banner__title--text">
          Hi, my name is {" "}
          <h1 className="banner__title banner__title--name">Fulvio</h1>
        </h1>
        <p className="banner__title banner__title--role">
          Frontend Developer
        </p>
        <p className="banner__paragraph">
            I'm a software engineer passionated about frontend technologies.<br></br>
            My main focus is usability and user experience and I love to develop
            customer centric applications built around clear and smooth user
            flows.
        </p>
      </div>
    </>
  )
}

export default Banner
